<template>
  <div class="society-container flex flex-col justify-around text-center">
    <img class="absolute top-4 left-1/2 logo mx-auto py-8 cursor-pointer" @click="exitUrl(7)" src="../assets/logos/louisxiii-white.svg" alt="">
    <div class="mx-4 h-3/4">
      <h1 class="text-xl md:text-xxl">Thank you for you <span class="text-quinary font-sangbleukingdom">participation.</span></h1>
      <!-- <h1 class="text-xl md:text-xxl">The <span class="text-quinary font-sangbleukingdom">LOUIS XIII MYSTERIES</span><br>experience is now over.</h1> -->
      <p class="text-md md:text-xl mt-8">The <span class="text-quinary font-sangbleukingdom">LOUIS XIII MYSTERIES</span><br class="md:hidden">&nbsp;experience is now over.<br>The winner of the draw will be contacted shortly by email.</p>
      <p class="text-md md:text-xl mt-20 mb-8">Discover the LOUIS&nbsp;XIII&nbsp;Collection without further delay</p>
      <NCta
        class="signup-button mx-auto"
        :borderRadius="'0px'"
        :backgroundColor="{default: colorStyle.tertiary, hover: colorStyle.quinary}"
        :textColor="{default: 'white', hover: colorStyle.tertiary}"
        :borderColor="{default: colorStyle.quinary, hover: colorStyle.quinary}"
        type="a"
        @click.native="exitUrl(1)"
      >DISCOVER</NCta>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import design from '@/validators/design'
import gsap from "gsap"

export default {
  name: 'EndPage',
  data () {
    return {
      colorStyle: design.colors,
      panoplyStyle: design.panoply,
    }
  },
  mounted() {
    let tlContainer = gsap.timeline()
    tlContainer.to('.society-container', {delay: 1, duration: 1.2, opacity: 1})
    let tlLogo = gsap.timeline()
    tlLogo.to('.logo', {delay: 1, duration: 1.2, y: 0, opacity: 1})
  },
  methods: {
    ...mapActions(['updateModalConfig']),
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName}/v1/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    }
  },
  computed: {
    ...mapGetters({
      idu: 'global/idu',
      token : 'global/token',
      visitId :'global/visitId',
      appConfig: 'config/appConfig'
    }),
  },
}
</script>

<style lang="scss" scoped>
  .society-container {
    background: url('../assets/bg/intro-min.jpg');
    background-size: cover;
    height: 100vh;
    opacity: 0;
    transition: all 1.2s ease;
    @media screen and (min-width: 768px) {
      background: url('../assets/bg/intro.jpg');
      background-size: cover;
    }

    .signup-button {
      width: 198px;
    }
    .logo {
      transition: all 1.2s ease;
      opacity: 0;
      transform: translate(-50%, -30px);
    }
  }
</style>